import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import {mergeObjects} from "@/utils/utils";
import Config from "@/config";
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    //base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    await store.commit("uiClearPageBeforeRouting", {to, from});
    next();
});
router.afterEach(async (to, from, next) => {

	// загружаем основные данные текущего роута:
	let loads = [];

	// загружаем данные сессии пользователя
    if ( !to.meta?.skipSession ) loads.push(store.dispatch("user/session"));

    // загружаем начальные данные текущего роута
    if (!to.params?.apiless && (to.meta?.api || to.meta?.action || to.meta?.actions)) {
        let actions;

        if (to.meta.actions) {
            actions = to.meta.actions;
        } else if (to.meta.action) {
            actions = [{
                action: to.meta.action,
                params: to.meta.params,
                query: to.meta.query,
            }];
        } else {
            // api style
            actions = [{
                action: 'fetchPage',
                method: to.meta.method,
                params: to.meta.params,
                query: to.meta.query,
            }];
        }

        actions.forEach(action => {
            const options = {
                //method: action.method || "GET",
                action: action.action,
                params: {
                    ...{},
                    ...action.params,
                    ...to.params,
                    ...to.query,
                },
                subscribe: action.subscribe
            };
            loads.push(store.dispatch("get", options));
        });
    }

    Promise.all(loads).then(values => {
        //console.log("VALUES", values);
        store.commit("uiClearPageAfterRouting", {to, from});
    }).catch((err) => {
        console.warn("API error for route " + to.name, err);
        if (err.code === Config.API_EXCEPTION_CODE_403) {
            router.replace({name: 'auth'});
        } else if (err.code === Config.API_EXCEPTION_CODE_404) {
            router.replace({name: 'error404'});
        } else {
            router.replace({name: 'error500'});
        }
    });
});

/*router.afterEach((to, from) => {
    store.state.routingTo = null;
    store.state.withMobileGroups = false;

    // небольшая задержка, чтобы в браузере сменился URL
    setTimeout(() => {
        // сообщаем Метрике явно, на какой URL пришли:
        //metrika.hit();

        // scroll to top
        window.scrollTo({
            'behavior': 'auto', //'smooth',
            'left': 0,
            'top': 0
        });

        store.state.isRoutingPending = false;

        // сообщаем в ГТМ, что страница доступна
        // if(typeof dataLayer) dataLayer.push({'event': 'render-event'});
    }, 500);

    // сообщаем пререндереру, что страница загрузилась:
    setTimeout(() => {
        document.dispatchEvent(new Event('render-event'));
    }, 1000);
});*/

export default router;

