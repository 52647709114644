import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";
import {date2Iso} from "@/utils/date";
import uiMutations from "@/store/ui/mutations";

const updateGroupItem = (state, payload) => {

}

const mutations = {
    groupUpdate: (state, payload) => {
        console.log("MUTATION group/update", payload);

        if (!payload?.groupAuth) return;
        if (!payload?.params) return;

        const update = (item) => {
            Object.keys(payload.params).forEach(key => {
                Vue.set(item, key, payload.params[key]);
            });
        }

        // update item in session
        const auth = payload.groupAuth;
        const items = state.session.area.groups;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) update(item);
        }

        // update item in context
        if (state.page.context.group && state.page.context.group.auth === auth) update(state.page.context.group);
    },
    groupFav: (state, payload) => {
        console.log("MUTATION group/fav", payload);
        if (!payload?.params) return;

        // remove archivedAt if groupgoes to favs
        const params = payload.params;
        if (params.isFav) {
            params.isArchived = false;
            params.archivedAt = null;
        }

        mutations.groupUpdate(state, payload);
    },
    groupArchive: (state, payload) => {
        console.log("MUTATION group/archive", payload);
        if (!payload?.params) return;

        const params = payload.params;
        if (params.isArchived) {
            params.archivedAt = date2Iso(new Date());
            params.isFav = false;
        } else {
            params.archivedAt = null;
            params.isArchived = false;
        }

        mutations.groupUpdate(state, payload);
    },
    groupTaskSort: (state, payload) => {
        console.log("MUTATION group/taskSort", payload);

        const groupAuth = payload.groupAuth;
        const topicAuth = payload.topicAuth;
        if (!groupAuth && !topicAuth) return;

        const newTaskSort = payload.taskSort;
        if ( !newTaskSort?.length ) return;

            // check the requested context is ours
        let context;
        if (topicAuth && topicAuth === state.page.context.topic?.auth) context = state.page.context.topic;
        else if (!topicAuth && groupAuth && groupAuth === state.page.context.group?.auth) context = state.page.context.group;
        else return;

        //console.log("CONTEXT topic", state.page.context.topic);
        //console.log("CONTEXT group", state.page.context.group);

        // apply to tasks
        const action = () => {
            const tasks = state.page.tasks.items;
            sortByAuth(tasks, newTaskSort);
        }

        // find which task is moved
        /*const sortedTab = state.page.context.tab;
        let currentTaskSort = context.taskSort;
        if ( typeof currentTaskSort === "string" ) {
            try { currentTaskSort = JSON.parse(currentTaskSort);}
            catch (e) {return;}
        }
        currentTaskSort = Array.isArray(currentTaskSort) ? currentTaskSort : currentTaskSort[sortedTab];
        const movedItem = newTaskSort.find((el, i)=>el !== currentTaskSort[i]);
        console.log("Moved item", movedItem);*/
        //uiMutations.uiSetActorOfGroupTaskSort(state, {userAuth: payload.userAuth});
        if ( payload.initiatorAuth && payload.taskAuth) {
            uiMutations.uiSetActorOfTask(state, {
                userAuth: payload.userAuth,
                subjectAuth: payload.taskAuth,
                icon: "mdi-hand-back-right-outline",
                timeout: 900,
            });
            setTimeout(action, 300);
        }
        else action();

        // todo apply to context.taskSort
        //Vue.set(context, "taskSort", context.taskSort);
        //console.log("context.taskSort", context.taskSort);

        /*const sourceIndex = payload.sourceIndex;
        const destinationIndex = payload.destinationIndex;
        const items = state.page.tasks.items;

        if (!items) return;
        if (sourceIndex < 0 || sourceIndex >= items.length) return;
        if (destinationIndex < 0 || destinationIndex >= items.length) return;

        if (sourceIndex !== destinationIndex) {
            const source = items[sourceIndex];
            items.splice(sourceIndex, 1);
            items.splice(destinationIndex, 0, source);
        }*/

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "group",
            auth: groupAuth,
        }];
        if (topicAuth) broadcast.push({
            entity: "topic",
            auth: topicAuth,
        });
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    groupTopicSort: (state, payload) => {
        console.log("MUTATION group/topicSort", payload);

        const group = state.session.area.groups.find(el => el.auth === payload.groupAuth);
        if (!group) return;

        sortByAuth(group.children, payload.topicSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        Vue.set(group, "topicSort", payload.topicSort);
        //console.log("group.topicSort", group.topicSort);

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "group",
            auth: group.auth,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },
    groupSort: (state, payload) => {
        console.log("MUTATION group/sort", payload);
        const user = state.session.user;
        if (!user) return;

        const groups = state.session.area.groups;
        if (!groups) return;

        sortByAuth(groups, payload.groupSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        user.groupSort = payload.groupSort;
    },
    groupAssign: (state, payload) => {
        console.log("MUTATION group/assign", payload);
        if (!payload?.groupAuth) return;
        //if (!payload?.assigneeAuth&&!payload?.ownerAuth) return;

        const auth = payload.groupAuth;
        const userAuth = payload.assigneeAuth;

        let userId = null;
        if (userAuth) {
            const user = state.session.area.users.find(el => el.auth === userAuth);
            //console.log("USER FOUND", user);
            if (user) userId = user.id;
            else return;
        }

        let property = "userId";

        // update item in a area
        const items = state.session.area.groups;
        let item;
        if (items?.length) {
            item = items.find(el => el.auth === auth);
            if (item) item[property] = userId;
        }

        // update item in context
        if (state.page.context.group?.auth === auth) state.page.context.group[property] = userId;

        // broadcast to subscribers of this
        const broadcast = [{
            entity: "group",
            auth: payload?.groupAuth,
        }];
        payload.broadcast = broadcast;
        console.log("broadcast", broadcast);
    },

    /**
     * @deprecated
     * @param state
     * @param payload
     */
    /*groupItem: (state, payload) => {
        console.log("MUTATION groupItem", payload);
        //console.log("\tpage data", data);
        if (!payload) return;
        /!*for (const key in payload) {
            Vue.set(state.ui, key, payload[key]);
        }*!/

        const group = state.page.context.group;
        if (group) {
            //storage.set(Config.STORAGE_GROUP_IDS, [group.id]);	// todo do we still need ids?
            if (typeof group.todoSort === "string") group.todoSort = JSON.parse(group.todoSort);
            if (typeof group.topicSort === "string") group.topicSort = JSON.parse(group.topicSort);
        }

        // clear pending items
        // small pause to make thins smoother
        setTimeout(() => {
            state.ui.pending.area = null;
            state.ui.pending.tab = null;
        }, 150);
    },*/
};

export default mutations;
