import {customAlphabet} from 'nanoid';
import config from "@/config";
import router from '@/router';
import storage from "@/utils/storage";
import Config from "@/config";
import {obtainClientAuth} from "@/utils/auth";

const getters = {

    page(state) {
        return state.page;
    },

    windowHeight(state) {
        return state.windowHeight;
    },
    /*
    * isWindowXS работает только для моб экрана, остальные геттеры по нарастанию
    * */
    isWindowXS(state) {
        return state.windowWidth < config.WIDTH_SM;
    },
    isWindowSM(state) {
        return state.windowWidth >= config.WIDTH_SM;
    },
    isWindowMD(state) {
        return state.windowWidth >= config.WIDTH_MD;
    },
    isWindowLG(state) {
        return state.windowWidth >= config.WIDTH_LG;
    },
    isWindowXL(state) {
        return state.windowWidth >= config.WIDTH_XL;
    },

    isRouteMy(state, getters) {
        const routeName = router.currentRoute.name;
        const routeParams = router.currentRoute.params;

        //console.log("routeName", routeName);

        const thisUserAuth = state.session.user?.auth;
        return (routeName === "assignee" && thisUserAuth === routeParams.assigneeAuth)
            || (getters.isRouteAssigneeTask && thisUserAuth === routeParams.assigneeAuth)
            || getters.isRouteMyTask
            || getters.isRouteMyTaskNew
            || (routeName === "my" && !!thisUserAuth);
    },

    isRouteMyTask(state) {
        const routeName = router.currentRoute.name;
        const routeParams = router.currentRoute.params;

        const thisUserAuth = state.session.user?.auth;
        return routeName === "my-todo" && !!thisUserAuth;
    },

    isRouteMyTaskNew(state) {
        const routeName = router.currentRoute.name;
        const routeParams = router.currentRoute.params;

        const thisUserAuth = state.session.user?.auth;
        return routeName === "my-todo-new" && !!thisUserAuth;
    },

    isRouteAssigneeTask(state) {

    },

    clientId() {
        return obtainClientAuth();
    },

    /*isAuthedToGroup(state) {
        if (!state.session.user?.auth) return false;

        //console.log(state.page['group']);

        const assignee = state.page['group']?.assignee
            || state.page['todo']?.assignee;
        if (assignee) return true;

        const group = state.page['group']?.group
            || state.page['todo']?.group;
        if (group) return state.session.userGroups?.findIndex(el => el.auth === group.auth) >= 0;

        return !!state.session.userGroups?.length;
    },*/
};
export default getters;
