import Vue from "vue";
import Config from "@/config";
import {sortByAuth} from "@/utils/group";

const mutations = {
    userSession: (state, payload) => {
        console.log("MUTATION userSession", payload);
        if (!payload) return;

        const area = state.session.area;

        // resort JSON-encoded data
        const user = payload.user;
        if ( user ) {
            if (typeof user.taskSort === "string") user.taskSort = JSON.parse(user.taskSort);
            if (typeof user.groupSort === "string") user.groupSort = JSON.parse(user.groupSort);
            sortByAuth(area.groups, user.groupSort);
        }

        // sort topics, set topics as children of groups
        if (area.topics?.length) {
            area.groups.forEach(group=>{
                ///if (typeof group.todoSort === "string") group.todoSort = JSON.parse(group.todoSort);
                if (typeof group.topicSort === "string") group.topicSort = JSON.parse(group.topicSort);
            });
            area.groups?.forEach(group => {
                const topics = area.topics?.filter(topic => topic.groupId === group.id);
                sortByAuth(topics, group.topicSort);
                Vue.set(group, "children", topics);
                Vue.set(group, "isGroup", true);

                //console.log("TOPICS of "+group.name, topics.map(el=>el.auth));
            });
            area.topics?.forEach(topic => {
                Vue.set(topic, "isTopic", true);
            });
        }
    },

    userTaskSort: (state, payload) => {
        console.log("MUTATION user/taskSort", payload);

        const user = state.session.user;
        if (!user) return;

        // apply to tasks
        const tasks = state.page.tasks.items;
        sortByAuth(tasks, payload.taskSort);
        //console.log("AFTER sort", group.children.map(c=>c.auth));

        // apply to user
        Vue.set(user, "taskSort", user.taskSort);
        //console.log("user.taskSort", user.taskSort);
    },

    userOnline: (state, payload) => {
        console.log("MUTATION userOnline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const exists = onlineUsers?.find(el=>el.auth === payload.userAuth);
        if ( !exists ) {
            const user = state.session.area.users.find(el=>el.auth === payload.userAuth);
            if ( user ) onlineUsers.push(user);
        }
    },

    userOffline: (state, payload) => {
        console.log("MUTATION userOffline", payload);
        if (!payload) return;
        if (!state.session.area?.users) return;  // session is not yet fetched

        const onlineUsers = state.session.area.onlineUsers;
        const index = onlineUsers?.findIndex(el=>el.auth === payload.userAuth);
        if ( index>=0 ) onlineUsers.splice(index, 1);
    },
};

export default mutations;
