import striptags from "striptags";
import {mysql2Date} from "@/utils/date";
import {TAB_ACTUAL, TAB_DELAYED, TAB_DONE, TAB_TEMPLATES} from "@/utils/task";

export const ACTIONS = {
    CREATE: "create",
    UPDATE: "update",
    DISCUSS: "discuss",
    DELAY: "delay",
    UNDELAY: "undelay",
    DONE: "done",
    UNDONE: "undone",
    PRIO: "prio",
    UNPRIO: "unprio",
    ASSIGN: "assign",
    DEADLINE: "deadline",
};

export const TAB_MSGS = "msgs";
export const TAB_PINS = "pins";
export const TAB_FILES = "files";
export const TAB_NAMES = [TAB_ACTUAL, TAB_DELAYED, TAB_DONE, TAB_TEMPLATES];

export const actions = [

    /*{text: "Готово!"},
    {text: "Мало информации"},
    {text: "Требуется помощь"},*/
    {
        id: ACTIONS.CREATE,
        //label: "Готово!", icon: "mdi-check",
        //text: "Задача выполнена",
        //type: "success",
        msg: "создал задачу",
    },
    {
        id: ACTIONS.DONE,
        label: "Готово!",
        icon: "mdi-check",
        text: "Задача выполнена",
        type: "success",
        msg: "отметил 'Выполнено'",
    },
    {
        id: ACTIONS.UNDONE,
        //label: "Не готово!", icon: "mdi-check",
        text: "Задача НЕ выполнена",
        type: "warning",
        msg: "отметил 'Не выполнено'",
    },
    {
        id: ACTIONS.DELAY,
        label: "Отложить",
        //icon: "mdi-timer-sand-empty",
        icon: "mdi-pause",
        text: "Задача откладывается",
        texted: "Задача отложена",
        withCalendar: true,
        type: "info",
        msg: "отложил задачу {{delayedTill}}",
    },
    {
        id: ACTIONS.UNDELAY,
        msg: "возобновил задачу",
    },
    {
        id: ACTIONS.PRIO,
        //label: "Важно!",
        icon: "mdi-fire",
        text: "Важная задача!",
        type: "error",
        msg: "поставил отметку 'Важно!'",
    },
    {
        id: ACTIONS.UNPRIO,
        msg: "снял отметку 'Важно!'",
    },
    {
        id: ACTIONS.DISCUSS,
        label: "Уточнить",
        icon: "mdi-chat-question-outline",
        text: "{{user.name}} просит {{assignee.name}} обсудить задачу",
        type: "warning",
        msg: "запросил обсуждение задачи",
        isForAssigneeOnly: true,
        withAssigneeMenu: true,
    },
    {
        id: ACTIONS.ASSIGN,
        //label: "Уточнить",
        icon: "mdi-chat-question-outline",
        type: "warning",
        msg: "назначил задачу на {{assignee}}",
        withAssignee: true,
    },
    {
        id: ACTIONS.DEADLINE,
        //label: "Готово!", icon: "mdi-check",
        //text: "Задача выполнена",
        type: "warning",
        msg: "поставил дедлайн {{deadline}}",
    },
    /*{label: "Задержка", icon: "mdi-chat-question-outline" text: "По данной задаче требуется дополнительное время"},*/
]