import striptags from "striptags";
import {iso2Date, iso2DDMMYYYY, iso2HHMM, mysql2Date} from "@/utils/date";
import Config from "@/config";
import {ACTIONS} from "@/utils/msg";
import store from "@/store";
import router from "@/router";

export const STATUSES = {
    DONE: "done",
    PRIO: "prio",
    DELAYED: "delayed",
    DEADLINE: "deadline",
    TEMPLATE: "template",
    REPEATED: "repeated",
};

export const TABS = {
    ACTUAL: "actual",
    DELAYED: "delayed",
    DONE: "done",
    TEMPLATES: "templates",
};

export const statuses = [
    {
        id: STATUSES.DONE,
        icon: "mdi-check",
        type: "success",
        appliesTo: item => item.isDone || item.doneAt,
        text: () => "Задача выполнена",
    },
    {
        id: STATUSES.PRIO,
        icon: "mdi-fire",
        type: "error",
        appliesTo: item => item.prio,
        text: () => "Важная задача!",
        onClick: item => {
            store.dispatch("task/update", {
                taskAuth: item.auth,
                params: {
                    "prio": !item.prio
                }
            });
        }
    },
    {
        id: STATUSES.DELAYED,
        icon: "mdi-timer-sand-empty",
        type: "info",
        appliesTo: item => item.delayedTill || item.isDelayed,
        text: (item) => {
            let text = "Задача отложена";
            if (item.delayedTill) {
                let hm = iso2HHMM(item.delayedTill);
                if (hm === "00:00") hm = "";
                else hm = " " + hm;
                text += " до " + iso2DDMMYYYY(item.delayedTill) + hm;
            }// else text += " бессрочно";
            return text;
        },
        onClick: item => {
            store.dispatch("task/dialogDelay", {
                item,
            });
        }
    },
    {
        id: STATUSES.DEADLINE,
        icon: "mdi-clock-time-eight-outline",
        type: "warning",
        appliesTo: item => item.deadline,
        text: item => "Дедлайн по задаче " + iso2DDMMYYYY(item.deadline),
        onClick: item => {
            store.dispatch("task/dialogDeadline", {
                item,
            });
        }
    },
    {
        id: STATUSES.TEMPLATE,
        icon: "mdi-view-grid-plus-outline",
        type: "info",
        //msg: "поставил отметку 'Важно!'",
        appliesTo: item => item.isTemplate,
        text(item) {
            if (!item.isTemplate) return null;

            let repeatDays = item.repeatDays;
            //console.log("type of repeatDays", this.item.auth, this.item.repeatDays, typeof repeatDays === "string");
            if (typeof repeatDays === "string") {
                try {
                    repeatDays = JSON.parse(repeatDays);
                } catch (e) {
                }
            }

            let repeatText = "";
            if (item.repeat === "hourly") {
                repeatText = "ежечасно";
            } else if (item.repeat === "daily") {
                repeatText = "ежедневно";
            } else if (item.repeat === "weekly" && repeatDays) {
                repeatText = "в дни недели: " + Config.DAY_LABELS.filter((el, index) => repeatDays.includes(index)).join(", ");
            } else if (item.repeat === "monthly" && repeatDays) {
                repeatText = "в дни месяца: " + repeatDays.map(el => el + 1).join(", ");
            }
            return "Задача повторяется " + repeatText;
        },
        onClick: item => {
            store.dispatch("task/dialogTemplate", {
                item,
            });
        }
    },
    {
        id: STATUSES.REPEATED,
        icon: "mdi-view-grid-plus-outline",
        type: "info",
        //msg: "поставил отметку 'Важно!'",
        appliesTo: item => item.templateId,
        text(item) {
            if (!item.templateId) return null;

            const template = store.state.page.task.template;
            if (template) {
                return "Задача создана из шаблона " + clearName(template.name);
            } else return "Задача создана из шаблона ";//+clearName(template.name);
        },
        onClick: item => {
            const template = store.state.page.task.template;

            // todo quick solution
            const path = router.currentRoute.path;
            //console.log("PATH", path);
            const newPath = path.replace(/(.+\/).+?$/, "$1" + template.auth);

            router.push(newPath);
        }
    },
]

/**
 * Возвращает первый параграф из текста описания задачи, очищенный от тэгов.
 * @param name
 * @returns {string|*}
 */
export const clearName = (name) => {
    if (!name) return "";

    if (name.match(/^\<p\>.+/ig)) {
        const ps = name.split("</p>");
        name = ps[0].substring(3);
    }

    // remove html tags
    name = striptags(name);

    // remove hashtags
    name = name.replace(/(^|\s|>)#[^<\s$]+/ig, "$1").trim();

    // remove html entities
    name = name.replace(/\&nbsp\;/ig, " ").trim();

    //if (name.length > 128) name = name.substr(0, 128) + "...";

    return name.trim();
}

export const parentsOf = (items, item) => {
    const parents = [];
    const findParentOf = (item) => {
        const parent = items.find(el => el.id === item.todoId);
        if (parent) {
            //console.log("parent of " + item.name + ": " + parent.name);
            findParentOf(parent);
            parents.push(parent);
        }
        //else console.log("no parent of " + item.name);
    }
    findParentOf(item);
    return parents.length ? parents : null;
}

export const childrenOf = (items, item) => {
    const children = [];
    const findChildrenOf = (item) => {
        const child = items.find(el => el.todoId === item.id);
        if (child) {
            //console.log("parent of " + item.name + ": " + parent.name);
            children.push(child);
            findChildrenOf(child);
        }
        //else console.log("no parent of " + item.name);
    }
    findChildrenOf(item);
    return children.length ? children : null;
}

export const actualItemsFrom = (items, todoSort, tabName) => {
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const now = new Date().valueOf();
    return sortedItems(items

        // filter out children of undone parents
        .filter(el => {
            if (el.todoId) {
                const parents = parentsOf(items, el);
                //if (el.auth === "KvTc88lSXYM0VsRN") console.log("parents", parents);
                // has all parents done?
                return !parents || parents?.reduce((all, item) => all && !!item.doneAt, true);
            }
            return true;
        })

        .filter(el => !el.isTemplate && !el.isPersonal)
        .filter(el => !el.delayedTill && !el.isDelayed)
        /*.filter(el => {
            // ignore minuses if there are active non-minus tags
            if (this.activeTags.find(el => !el.isMinus)) return true;
            // remove inactive minus-tagged, but not with prio
            if (el.prio) return true;
            return !this.tagsOf(el).some(text => {
                const tag = this.tags.find(tag => tag.name === text);
                return tag?.isMinus && !tag.active;
            });
        })*/
        .filter(el => {
            // add done today items
            if (!el.doneAt) return true;
            if (el.isDone) return true;
            const d = iso2Date(el.doneAt).valueOf();
            const days = Math.floor((now - d) / millisecondsPerDay);
            return days <= 0;
        }), todoSort, tabName)

        // filter out done parents of done items
        .filter(el => {
            if (el.doneAt || el.isDone) {
                //console.log("TASK "+el.name+" DONE");
                const doneChild = items.find(child => child.todoId === el.id);
                //console.log("\thas done child", doneChild);
                return !doneChild || !doneChild.doneAt;
            }
            return true;
        });
};

/**
 * Should be same as on backend.
 * @deprecated
 * @param items
 * @param todoSort
 * @param tabName
 * @param force
 * @returns {*}
 */
export const sortedItems = (items, todoSort, tabName, userId = null, force = false) => {
    const indexes = {};
    const indexOfId = (id) => {
        let index = -1;
        if (indexes['id' + id]) index = indexes['id' + id];
        else if (Array.isArray(todoSort[tabName])) index = indexes['id' + id] = todoSort[tabName].findIndex(el => el === id);
        else if (Array.isArray(todoSort)) index = indexes['id' + id] = todoSort.findIndex(el => el === id);
        return index;
    }
    return items?.sort((a, b) => {
        //флаги, баги, текущие, тесты, выполненные сегодня

        //if ( a.sort !== null && b != null) return a - b;

        if (force) {
            if (userId) {
                if (a.userId === userId && b.userId !== userId) return -1;
                if (a.userId !== userId && b.userId === userId) return 1;
            }

            if (!a.doneAt && b.doneAt) return -1;
            if (a.doneAt && !b.doneAt) return 1;

            if (!a.delayedTill && b.delayedTill) return -1;
            if (a.delayedTill && !b.delayedTill) return 1;

            if (!a.isDelayed && b.isDelayed) return -1;
            if (a.isDelayed && !b.isDelayed) return 1;

            if (a.prio && !b.prio) return -1;
            if (!a.prio && b.prio) return 1;
        }

        // take group sorting
        //tabIndex = tabIndex || 0;
        //const tabName = TAB_NAMES[tabIndex];
        //console.log("todoSort", todoSort, tabName);

        if (todoSort) {
            const aIndex = indexOfId(a.id);
            const bIndex = indexOfId(b.id);
            //if (aIndex < 0 && bIndex >= 0) return -1;
            //if (aIndex >= 0 && bIndex < 0) return 1;
            return aIndex - bIndex;
        }

        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;

        /*if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;*/

        return 0;
    });
};

export const sortItems = (items) => {
    return items?.sort((a, b) => {
        /*if (userId) {
                        if (a.userId === userId && b.userId !== userId) return -1;
                        if (a.userId !== userId && b.userId === userId) return 1;
                    }*/

        if (!a.doneAt && b.doneAt) return -1;
        if (a.doneAt && !b.doneAt) return 1;

        if (!a.delayedTill && b.delayedTill) return -1;
        if (a.delayedTill && !b.delayedTill) return 1;

        if (!a.isDelayed && b.isDelayed) return -1;
        if (a.isDelayed && !b.isDelayed) return 1;

        if (a.prio && !b.prio) return -1;
        if (!a.prio && b.prio) return 1;

        if (a.updatedAt > b.updatedAt) return -1;
        if (a.updatedAt < b.updatedAt) return 1;

        /*if (a.createdAt && !b.createdAt) return -1;
        if (!a.createdAt && b.createdAt) return 1;
        if (a.createdAt > b.createdAt) return -1;
        if (a.createdAt < b.createdAt) return 1;*/

        return 0;
    });
};