<template>
	<v-avatar small size="24" class="avatar" :title="title">
		<v-img v-if="user.avatar" :src="user.avatar?.srcThumb" :lazy-src="user.avatar?.srcLazy" />
		<span v-else class="caption">{{fi}}</span>
	</v-avatar>
</template>

<script>
import * as userUtils from "@/utils/user";

export default {
	name: "avatar",
	props: {
		user: {},
	},
	data: () => ({}),
	computed: {
		fi() {
			return userUtils.fi(this.user);
		},
		title() {
			return userUtils.nick(this.user);
		},
	}
}
</script>

<style lang="scss">
.avatar {
	width: 28px;
	height: 28px;
	overflow: hidden;
	text-transform: uppercase;
}
</style>